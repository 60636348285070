import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/zh-cn";

import VueTinymce from '@packy-tang/vue-tinymce'
import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)
Vue.use(VueTinymce);
Vue.config.productionTip = false
Vue.prototype.$baseURL=window.publicConfig.BASE_API
dayjs.extend(relativeTime);
Vue.prototype.$dayJS = dayjs;


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
